/* eslint-disable default-case */
import React, { useEffect, useRef, useState } from "react";
import Phaser from "phaser";
import bgImage from "./assets/Arena.jpg";
import bgImage2 from "./assets/bg_trans.png";
import kapi from "./assets/kapı4Transparent.png";
import kapi4 from "./assets/kapı4.png";
import karpiKirmizi from "./assets/kapı kırmızı 4.png";
import io from "socket.io-client";
import "./App.css"

const App = () => {
  const gameRef = useRef(null);
  const [spriteData, setSpriteData] = useState([]);
  const [params, setParams] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 900) {
      setIsMobile(true);
    }
  },[window.innerWidth])


  console.log("isMobile", isMobile)

  useEffect(() => {
    // URL'deki sorgu dizesini alıyoruz.
    const searchParams = new URLSearchParams(window.location.search);

    // Her bir parametreyi bir objeye atıyoruz.
    let params = {};
    for (let param of searchParams) {
      params[param[0]] = param[1];
    }

    // Parametreleri duruma ayarlıyoruz.
    setParams(params);
  }, []);

  useEffect(() => {
    if (params) {
      const clan_id = params.clan_id || 14;
      const home_clan_id = params.home_clan_id;
      const guest_clan_id = params.guest_clan_id;
      const game_id = params.game_id || '14-a2e6938cc40aa6f93f489ff236ff7ffe';
      const player_id = params.player_id ||'0x18958BA039F66dC7Ec399a04227d0D0F032D2493';
      const config = {
        type: Phaser.AUTO,
        width: 1000,
        height: 900,
        scale: {
          mode: isMobile ? Phaser.Scale.RESIZE : Phaser.Scale.FIT,
        },
        scene: {
          preload: preload,
          create: create,
          update: update,
        },
        audio: {
          disableWebAudio: true,
        },
      };

      let movingSprites = [];

      function preload() {
        this.load.image("bg", bgImage);
        this.load.image("bg2", bgImage2);
        // this.load.image("door", kapi);
        // this.load.image("doorActive", kapi4);
        // this.load.image("doorRed", karpiKirmizi);
        // LOAD SPRITE SHEET
        this.load.atlas(
          "slash",
          "assets/Anims/Slash.png",
          "assets/Anims/Slash.json"
        );
        // LOAD SPRITE SHEET
        this.load.atlas("run", "assets/Anims/run.png", "assets/Anims/run.json");
        /** GAME Sounds **/
        // this.load.audio("theme", [
        //   "/assets/Sounds/game_music.mp3",
        // ]);
        // hit sound
        // this.load.audio("hit", [
        //   "/assets/Sounds/hit.mp3",
        // ]);
        // //dead sound
        // this.load.audio("dead", [
        //   "/assets/Sounds/dead.mp3",
        // ])
        // // respawn sound
        // this.load.audio("respawn", [
        //   "/assets/Sounds/respawn.mp3",
        // ])
        // // security sound
        // this.load.audio("security", [
        //   "/assets/Sounds/security.mp3",
        // ])
      }

      function create() {
        const bg = this.add.image(0, 0, "bg").setOrigin(0);

        // // game theme music
        // const theme = this.sound.add("theme");
        // // avaxtar hit sound
        // const hit = this.sound.add("hit");
        // // avaxtar dead sound
        // const dead = this.sound.add("dead")
        // // avaxtar spawn sound
        // const respawn = this.sound.add("respawn")
        // // avaxtar security sound
        // const security = this.sound.add("security")

        // setDepth() => zIndex

        bg.displayWidth = this.scale.width;
        bg.displayHeight = this.scale.height;

        const socket = io('https://avaxtars-minigame.rglabs.co');

        // RUNNING ANIMATION
        this.anims.create({
          key: "running", // the name of the animation
          frames: this.anims.generateFrameNames("run", {
            // We specify the start and end frames, using the frames in the JSON file
            start: 0,
            end: 19,
            zeroPad: 2,
            prefix: "move_",
            suffix: ".png",
          }),
          frameRate: 60,
          repeat: -1, // repeat indefinitely
        });

        // SLASH ANIMATION
        this.anims.create({
          key: "slash_anim", // the name of the animation
          frames: this.anims.generateFrameNames("slash", {
            start: 0,
            end: 8,
            zeroPad: 1,
            prefix: "shoot_",
            suffix: ".png",
          }),
          frameRate: 60, // FPS
          repeat: -1, // repeat indefinitely
        });


        socket.on("connect", () => {
          const options = {
            game_id,
            player_id,
          };
          socket.emit("join_game", options);
          socket.on("log", (data) => {
            if (data?.params?.state) {
              const mappedData = Object.entries(data.params.state).map(([key, value]) => {
                return { id: key, ...value };
              });
              mappedData?.forEach((avatar) => {
                const clanId = avatar.clanId;
                const order = avatar.order;
                const avaxtarId = avatar.id;
                if (clanId == clan_id) {
                  switch (order) {
                    case 0:
                      createAvaxtar(avaxtarId, this.scale.width / 4, 30, clanId);
                      break;
                    case 1:
                      createAvaxtar(avaxtarId, this.scale.width / 2.7, 30, clanId);
                      break;
                    case 2:
                      createAvaxtar(avaxtarId, this.scale.width / 2, 30, clanId);
                      break;
                    case 3:
                      createAvaxtar(avaxtarId, this.scale.width / 1.61, 30, clanId);
                      break;
                    case 4:
                      createAvaxtar(avaxtarId, this.scale.width / 1.5, 30, clanId);
                      break;
                    case 5:
                      createAvaxtar(avaxtarId, this.scale.width / 2, 30, clanId);
                      break;
                  }
                } else {
                  switch (order) {
                    case 0:
                      createAvaxtar(avaxtarId, this.scale.width / 4, this.scale.height / 1, clanId);
                      break;
                    case 1:
                      createAvaxtar(avaxtarId, this.scale.width / 2.7, this.scale.height / 1, clanId);
                      break;
                    case 2:
                      createAvaxtar(avaxtarId, this.scale.width / 2, this.scale.height / 1, clanId);
                      break;
                    case 3:
                      createAvaxtar(avaxtarId, this.scale.width / 1.61, this.scale.height / 1, clanId);
                      break;
                    case 4:
                      createAvaxtar(avaxtarId, this.scale.width / 1.5, this.scale.height / 1, clanId);
                      break;
                    case 5:
                      createAvaxtar(avaxtarId, this.scale.width / 2, this.scale.height / 1, clanId);
                      break;
                  }
                }
              });
            }
            if (data.action === "avaxtar_moving") {
              const clanId = data.params.clanId;
              const order = data.params.order;
              const avaxtarId = data.params.avaxtarId;


              if (clanId == clan_id) {
                switch (order) {
                  case 0:
                    createAvaxtar(avaxtarId, this.scale.width / 4, 30, clanId);
                    break;
                  case 1:
                    createAvaxtar(avaxtarId, this.scale.width / 2.7, 30, clanId);
                    break;
                  case 2:
                    createAvaxtar(avaxtarId, this.scale.width / 2, 30, clanId);
                    break;
                  case 3:
                    createAvaxtar(avaxtarId, this.scale.width / 1.61, 30, clanId);
                    break;
                  case 4:
                    createAvaxtar(avaxtarId, this.scale.width / 1.5, 30, clanId);
                    break;
                  case 5:
                    createAvaxtar(avaxtarId, this.scale.width / 2, 30, clanId);
                    break;
                }
              } else {
                switch (order) {
                  case 0:
                    createAvaxtar(avaxtarId, this.scale.width / 4, this.scale.height / 1, clanId);
                    break;
                  case 1:
                    createAvaxtar(avaxtarId, this.scale.width / 2.7, this.scale.height / 1, clanId);
                    break;
                  case 2:
                    createAvaxtar(avaxtarId, this.scale.width / 2, this.scale.height / 1, clanId);
                    break;
                  case 3:
                    createAvaxtar(avaxtarId, this.scale.width / 1.61, this.scale.height / 1, clanId);
                    break;
                  case 4:
                    createAvaxtar(avaxtarId, this.scale.width / 1.5, this.scale.height / 1, clanId);
                    break;
                  case 5:
                    createAvaxtar(avaxtarId, this.scale.width / 2, this.scale.height / 1, clanId);
                    break;
                }
              }
            }

            if (data.action === "avaxtar_hp") {
              // updateAvaxtarHealth(data.params.avaxtar_id, data.params.hp)
            }

            if (data.action === "combat_hit" || data.action === "combat_critical_hit") {
              // console.log("battle avaxtars")
              // battleAvaxtars(data.params.avaxtar_id);
            }

            if (data.action === "combat_started") {
              battleAvaxtars(data.params.avaxtar_id_1, data.params.avaxtar_1_clan_id);
              battleAvaxtars(data.params.avaxtar_id_2, data.params.avaxtar_2_clan_id);
            }

            if (data.action === "combat_won") {
              setTimeout(() => {
                destroyLoserAvaxtar(data.params.loser_avaxtar_id, data.params.loser_avaxtar_clan_id);
                destoryWinnerAvaxtar(data.params.winner_avaxtar_id, data.params.winner_avaxtar_clan_id);
              }, 1500)
            }
          });
        });

        const createAvaxtar = (name, x, y, clanId) => {
          const isExistingAvatar = movingSprites.some(
            (avatar) => avatar.sprite.name == name
          );

          if (isExistingAvatar) {
            return;
          }

          const newSprite = this.add
            .sprite(x, y)
            .setName(name)
            .setScale(0.6);

          if (clan_id == clanId) {
            newSprite.angle = 90;
          } else {
            newSprite.angle = 270;
          }
          newSprite.play("running");

          newSprite.health = 100;
          if (clan_id != clanId) {
            newSprite.flipY = true;
          }

          let text = this.add.text(newSprite.x - newSprite.displayWidth / 2, newSprite.y + newSprite.displayHeight / 2, name, {
            fontSize: '14px',
            fill: '#ffffff'
          });

          this.tweens.add({
            targets: newSprite,
            x: x,
            y: clanId != clan_id ? this.scale.height / 2 - 20 : this.scale.height / 2 + 20,
            ease: "Linear",
            duration: 7000,
            onComplete() {
            }
          });

          movingSprites.push({ sprite: newSprite, text: text });

          setSpriteData((prevSpriteData) => [
            ...prevSpriteData,
            { name: name, health: newSprite.health },
          ]);

        };

        const destoryWinnerAvaxtar = (name, clanId) => {
          const avatarIndex = movingSprites.findIndex((avxtr) => avxtr.sprite.name == name);

          if (avatarIndex === -1) {
            console.log("Avatar does not exist in the array.");
            return;
          }

          const avatar = movingSprites[avatarIndex];
          if (avatar.text) {
            avatar.text.destroy();
          }
          if (avatar.sprite) {
            avatar.sprite.destroy();
          }
          movingSprites.splice(avatarIndex, 1);
        };
        const destroyLoserAvaxtar = (name, clan_id) => {
          const avatarIndex = movingSprites.findIndex((avxtr) => avxtr.sprite.name == name);

          if (avatarIndex === -1) {
            console.log("Avatar does not exist in the array.");
            return;
          }

          const avatar = movingSprites[avatarIndex];
          if (avatar.text) {
            avatar.text.destroy();
          }
          if (avatar.sprite) {
            avatar.sprite.destroy();
          }
          movingSprites.splice(avatarIndex, 1);
        };



        const battleAvaxtars = (name, clanId) => {
          const avatar = movingSprites.find(
            (avxtr) => avxtr.sprite.name == name
          );

          if (!avatar) {
            return;
          }

          avatar.sprite.play("slash_anim")
          this.tweens.killTweensOf(avatar.sprite);
          avatar.sprite.setFrame(120)
          this.tweens.add({
            targets: avatar.sprite,
            x: this.scale.width / 2,
            y: this.scale.height / 2 + (clan_id === clanId ? - 50 : 50),
            ease: "Linear",
            duration: 300,
            onComplete: () => {
            },
          });
          const { sprite } = avatar;
        };

        const avaxtarWin = (name, clanId) => {
          const avatar = movingSprites.find(
            (avxtr) => avxtr.sprite.name == name
          );

          if (!avatar) {
            // console.log("Avatar does not exist in the array.");
            return;
          }

          const { sprite } = avatar;

          this.tweens.add({
            targets: sprite,
            y: sprite.y + 400,
            ease: "Linear",
            duration: 1000,
            onComplete: () => {

            },
          });
        };
      }

      function update(time, delta) {
        movingSprites.forEach((obj) => {
          if (obj.sprite && obj.text) {
            // Metni sprite'ın hemen üstüne yerleştir
            obj.text.setPosition(
              obj.sprite.x, // X koordinatını aynı tut
              obj.sprite.y,
            );
            obj.text.setOrigin(0.5, 1); // Metni X ekseninde ortala ve Y ekseninde üst tarafa hizala
          }
        });
      }


      const game = new Phaser.Game(config); // game config
      gameRef.current = game;
      return () => {
        game.destroy(true);
      };
    }
  }, [params]);


  return (
    <div>
      <div style={{ width: "900px", margin: "0 auto" }} ref={gameRef}></div>
    </div>
  );
};

export default App;

